/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { GrPowerReset } from 'react-icons/gr';
import { toast } from 'react-toastify';
import { storage } from '../../firebase';
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage';
import AxiosInstanceUserServer from '../axios/AxiosInstanceUserServer';
import TOSAcceptanceModal from '../modals/TOSAcceptanceModal';
import CountrySelector from '../CountrySelector';
import PhoneNumberInput from '../PhoneNumberInput';
import IdentityDcoumentData from "../../IdentityDocuments.json";

const IdentityDcouments = JSON.parse(JSON.stringify(IdentityDcoumentData));

export default function RequestCorrectionForm(props) {
  useEffect(() => {
    if (props.tokenData.owner) {
      formatOwnerDOB();
      getCountryLabel(props.tokenData.owner.country, 1);
      ownerCountryChange(
        props.tokenData.owner ? props.tokenData.owner.country : 'JO'
      );
      setOwnerCity(props.tokenData.owner.city);
      setOwnerFirstLegalName(props.tokenData.owner.first_legal_name);
      setOwnerLastLegalName(props.tokenData.owner.last_legal_name);
      setPhoneNumber(`+${props.tokenData.owner.phone_number}`);
      setOwnerAddress(props.tokenData.owner.address);
      setOwnerPostalCode(props.tokenData.owner.postal_code);
      setBusinessType(props.selectedMerchantData.business_type);
      setRegistrationNumber(props.selectedMerchantData.registration_number);
      setMerchantLegalName(props.selectedMerchantData.legal_name);
      setMerchantDisplayName(props.selectedMerchantData.display_name);
      setMerchantSupportEmail(props.selectedMerchantData.support_email);
      setMerchantPhoneNumber(`+${props.selectedMerchantData.phone_number}`);
      setMerchantWebsiteLink(props.selectedMerchantData.website_link);
      setCompanyStructure(props.selectedMerchantData.company_structure);
      setMerchantCountry(
        props.selectedMerchantData.country ? props.selectedMerchantData.country : { ...ownerCountry }
      );
      setMerchantCity(props.selectedMerchantData.city);
      setMerchantLine1(props.selectedMerchantData.line1);
      setMerchantLine2(props.selectedMerchantData.line2);
      setMerchantPostalCode(props.selectedMerchantData.postal_code);
    }
  }, [props.tokenData]);

  // Status Correction
  useEffect(() => {
    arrays2();
    if (props.selectedMerchantData.stripe_status_reason) {
      setOwnerArr(ArrayOwner);
      setMerchantArr(ArrayMerchant);
    }
  }, [props.tokenData]);

  const [ownerArr, setOwnerArr] = useState([]);
  const [MerchantArr, setMerchantArr] = useState([]);

  var ArrayOwner = [];
  // let stripeMerchantReasons = (props.selectedMerchantData.stripe_status_reason).split(", ");
  var ArrayMerchant = [/*...stripeMerchantReasons*/];
  function arrays2() {
    var String = '';
    var Array = [];
    String = `${props.selectedMerchantData.stripe_status_reason}`;
    // 'owner-first_legal_name,merchant-display_name,owner-last_legal_name,merchant-phone_number';

    Array = String.split(',');
    for (var i = 0; i < Array.length; i++) {
      var arr = Array[i].split('-');

      if (arr[0] === 'owner') {
        ArrayOwner.push(arr[1]);
      } else if (arr[0] === 'merchant') {
        ArrayMerchant.push(arr[1]);
      }
    }
  }

  // States for the Owner Data
  //1 First Legal Name
  const [ownerFirstLegalName, setOwnerFirstLegalName] = useState();
  //2 Last Legal Name
  const [ownerLastLegalName, setOwnerLastLegalName] = useState();
  //3 DOB
  let dateString;
  const [ownerDob, setOwnerDob] = useState(dateString);
  function formatOwnerDOB() {
    if (props.tokenData.owner.dob) {
      const months = [
        '',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      let date = props.tokenData.owner.dob.split(' ');
      dateString = `${date[3]}-${months.indexOf(date[2])}-${date[1]}`;

      setOwnerDob(dateString);
    }
  }
  //4 Country
  const [ownerCountry, setOwnerCountry] = useState({
    value: 'JO',
    label: 'Jordan',
  });
  //5 City
  const [ownerCity, setOwnerCity] = useState();
  const [ownerCitiesOptions, setOwnerCitiesOptions] = useState([
    { name: 'Amman' },
    { name: 'Irbid' },
    { name: "Az Zarqa'" },
    { name: 'Al `Aqabah' },
    { name: 'As Salt' },
    { name: 'Al Mafraq' },
    { name: 'Jarash' },
    { name: 'Ma`An' },
    { name: 'At Tafilah' },
    { name: 'Al Karak' },
    { name: 'Madaba' },
    { name: '`Ajlun' },
    { name: 'At Turrah' },
    { name: 'Sakib' },
  ]);
  //6 Owner Address
  const [ownerAddress, setOwnerAddress] = useState('');
  //7 Phone Number
  const [ownerPhoneNumber, setPhoneNumber] = useState('');

  // States for the Merchant Data
  const [businessType, setBusinessType] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [merchantLegalName, setMerchantLegalName] = useState('');
  const [merchantDisplayName, setMerchantDisplayName] = useState('');
  const [merchantSupportEmail, setMerchantSupportEmail] = useState('');
  const [merchantPhoneNumber, setMerchantPhoneNumber] = useState('');
  const [merchantWebsiteLink, setMerchantWebsiteLink] = useState('');
  const [companyStructure, setCompanyStructure] = useState(
    'Sole Proprietorship'
  );
  const [merchantCountry, setMerchantCountry] = useState({
    value: 'JO',
    label: 'Jordan',
  });
  const [merchantCity, setMerchantCity] = useState();
  const [merchantCitiesOptions, setMerchantCitiesOptions] = useState([
    { name: 'Amman' },
    { name: 'Irbid' },
    { name: "Az Zarqa'" },
    { name: 'Al `Aqabah' },
    { name: 'As Salt' },
    { name: 'Al Mafraq' },
    { name: 'Jarash' },
    { name: 'Ma`An' },
    { name: 'At Tafilah' },
    { name: 'Al Karak' },
    { name: 'Madaba' },
    { name: '`Ajlun' },
    { name: 'At Turrah' },
    { name: 'Sakib' },
  ]);
  const [merchantLine1, setMerchantLine1] = useState('');
  const [merchantLine2, setMerchantLine2] = useState('');
  const [merchantPostalCode, setMerchantPostalCode] = useState('');
  const [ownerPostalCode, setOwnerPostalCode] = useState('');
  const [stripeOwnerID, setStripeOwnerID] = useState("");

  // GET the Country Label Full Name by Giving the ISO2 Name
  function getCountryLabel(value, form) {
    const options = {
      method: 'GET',
      url: `https://api.api-ninjas.com/v1/country?name=${value}`,
      headers: {
        'X-Api-Key': 'xDoicZeqn2WcLBx3x18fyQ==SqNOmz757IG4BhZF',
      },
    };
    axios.request(options).then((res) => {
      if (form === 1 && res.data.length > 0 && res.data[0].name) {
        setOwnerCountry({
          value: value,
          label: res.data[0].name,
        });
      } else if (form === 2) {
        setMerchantCountry({
          value: value,
          label: res.data[0].name,
        });
      }
    }).catch((e) => {
      toast.error("Something Went Wrong in Get Country Label!");
      console.error(e);
    });
  }

  // Change the Cities Options for the Owner City Based on the Selected Country by Sending a request to Ninja API
  function ownerCountryChange(givingCountry) {
    if (!ownerCity) return;
    const options = {
      method: 'GET',
      url: `https://api.api-ninjas.com/v1/city?country=${givingCountry.value ? givingCountry.value : givingCountry
        }&limit=1000`,
      headers: {
        'X-Api-Key': 'xDoicZeqn2WcLBx3x18fyQ==SqNOmz757IG4BhZF',
      },
    };
    axios.request(options).then((res) => {
      setOwnerCitiesOptions(res.data);
      // Set the City State Value Based on the New Selected City
      setOwnerCity(
        res.data[
          ownerCitiesOptions.findIndex((option) => option.name === ownerCity) >
            res.data.length
            ? 0
            : ownerCitiesOptions.findIndex(
              (option) => option.name === ownerCity
            )
        ].name
      );
      getCountryLabel(givingCountry, 1);
    }).catch((e) => {
      console.error(e);
      toast.error("Something Went Wrong in Owner Country Change!");
    });
  }

  // Change the Cities Options for the Merchant City Based on the Selected Country by Sending a request to Ninja API
  function merchantCountryChange(givingCountry) {
    const options = {
      method: 'GET',
      url: `https://api.api-ninjas.com/v1/city?country=${givingCountry.value ? givingCountry.value : givingCountry
        }&limit=1000`,
      headers: {
        'X-Api-Key': 'xDoicZeqn2WcLBx3x18fyQ==SqNOmz757IG4BhZF',
      },
    };
    axios.request(options).then((res) => {
      setMerchantCitiesOptions(res.data);
      // Set the City State Value Based on the New Selected City
      setMerchantCity(
        res.data[
          merchantCitiesOptions.findIndex(
            (option) => option.name === ownerCity
          ) > res.data.length
            ? 0
            : merchantCitiesOptions.findIndex(
              (option) => option.name === ownerCity
            )
        ].name
      );
      getCountryLabel(givingCountry, 2);
    }).catch((e) => {
      toast.error("Something Went Wrong in Merchant Country Change!");
      console.error(e);
    });
  }

  // Submit the Form
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    var reg = /^[a-zA-Z0-9._ -]+$/;

    // The regex only allow 3 to 22 characters, the last character cannot be a space, and must inlude at least one alphabetical character
    // Allowed special characters are-->  $@$!%*?&#-_. +
    var regDisplayName = /^(?=.*[a-zA-Z])(?!.*\s$)(?=.{5,22}$)[a-zA-Z0-9$@$!%?&#_. +\u0600-\u06FF]+$/;

    if (!merchantCountry && businessType === "individual") {
      setMerchantCountry(ownerCountry);
    }

    // Check if the Form is Valid
    if (form.checkValidity()) {
      if (
        !imageUploadVerificationDocument &&
        ownerArr.includes('verification_document')
      ) {
        toast.error('Please Upload Your Verification Document');
        return;
      }
      if (!reg.test(ownerFirstLegalName) || !reg.test(ownerLastLegalName)) {
        toast.error(
          'Please enter first name and last name only English characters'
        );
        return;
      }
      if (!regDisplayName.test(merchantDisplayName)) {
        toast.error('Please enter the display name without / or \\');
        return;
      }
      if (
        !imageUploadVerificationDocumentBack &&
        selectVerificationType === '0' &&
        ownerArr.includes('verification_document')
      ) {
        toast.error('Please Upload Your Verification Document');
        return;
      }
      if (
        businessType === 'company' &&
        imageUploadRegistrationCertificate === null &&
        MerchantArr.includes('registration_certificate')
      ) {
        toast.error('Please Upload Your Registration Certificate');
        return;
      }
      if (!isValidPhoneNumber(ownerPhoneNumber)) {
        toast.error('Invalid Phone Number it Should be Like +XXX XX XXX XXXX');
        return;
      }
      const response = await AxiosInstanceUserServer.get(`/retrieve-owner-id/${props.selectedMerchantData.id}`);
      if (!response || !response.data || !response.data.OwnerID) return;
      setStripeOwnerID(response.data.OwnerID);
      uploadVerificationImage(response.data.OwnerID);; // to upload Verification Image
      if (
        imageUploadVerificationDocumentBack &&
        selectVerificationType === '0'
      ) {
        uploadVerificationImageBack(response.data.OwnerID);
      }
      // to upload Registration Image
      uploadRegistrationImage();

      setTosModalShow(true);
      setLoading(false);
    } else {
      event.stopPropagation();
    }
    setValidated(true);
  };

  // Send Request to PUT the Owner & Merchant Information
  const updateRequest = async () => {
    let phone_number = ownerPhoneNumber.slice(1); // Delete the First Two Characters '+ '
    const ownerObject = {
      first_legal_name: ownerFirstLegalName,
      last_legal_name: ownerLastLegalName,
      dob: ownerDob,
      phone_number: phone_number,
      country_of_residence: ownerCountry.value,
      city: ownerCity,
      address: ownerAddress,
      postal_code: ownerPostalCode,
    };
    if (stripeOwnerID !== props.selectedMerchantData.owner_id) {
      await AxiosInstanceUserServer.post(
        `/update-person/${props.tokenData.owner.id}/${stripeOwnerID}/${props.selectedMerchantData.id}`,
        ownerObject,
      )
        .catch((e) => {
          toast.error("Something Went Wrong in Update Request!");
          console.error(e);
        });
    } else {
      await AxiosInstanceUserServer.put(
        `/owner/update/${stripeOwnerID}`,
        ownerObject,
      )
        .catch((error) => {
          console.error("Error trying to update TOSAcceptance:", error)
          let errorMessage = 'Something Went Wrong, Please Try Again'; 
          if(error.code === 400) {
            errorMessage = error.response.data;
          }
          toast.error(errorMessage);
        });
    }

    let merchant_phone_number = merchantPhoneNumber.slice(1); // Delete the First Two Characters '+ '
    await AxiosInstanceUserServer.put(
      `/merchant/update/${props.selectedMerchantData.id}`,
      {
        business_type: businessType,
        display_name: merchantDisplayName,
        legal_name: merchantLegalName,
        registration_number: registrationNumber,
        company_structure: businessType === 'company' ? companyStructure : null,
        support_email: merchantSupportEmail,
        phone_number: merchant_phone_number,
        website_link: merchantWebsiteLink,
        country: merchantCountry.value,
        city: merchantCity,
        line1: merchantLine1,
        line2: merchantLine2,
        postal_code: merchantPostalCode,
        statement_descriptor: merchantDisplayName,
        verification_type: selectVerificationType
      }
    ).then((response) => {
      console.log('response :>> ', response);
    })
    .catch((error) => {
      console.error("Error trying to update TOSAcceptance:", error)
      let errorMessage = 'Something Went Wrong, Please Try Again'; 
      if(error.code === 400) {
        errorMessage = error.response.data;
      }
      toast.error(errorMessage);
    });
  };

  // to upload Verification Document image watch this video => https://www.youtube.com/watch?v=YOAeBSCkArA
  const [imageUploadVerificationDocument, setImageUploadVerificationDocument] =
    useState();
  const [
    imageUploadVerificationDocumentBack,
    setImageUploadVerificationDocumentBack,
  ] = useState();

  const uploadVerificationImage = (ownerID) => {
    if (!imageUploadVerificationDocument) return;

    const isJpgOrPng =
      imageUploadVerificationDocument.type === 'image/jpg' ||
      imageUploadVerificationDocument.type === 'image/png';

    if (!isJpgOrPng) {
      toast.error(
        'You can only upload JPG, or PNG file! VerificationDocument'
      );
      return;
    }
    // change name of folder from here
    // Generate the filename based on the selected document type
    let filename = '';
    //  value={'1'} => National Id / value={'2'} => Passport / value={'3'} => Driving License
    switch (selectVerificationType) {
      case '0':
        filename = `${props.tokenData.owner.email}_${ownerID}/${props.selectedMerchantData.display_name}_${props.selectedMerchantData.id}/VerificationDocument/VerificationDocumentFront`
        break;
      case '1':
        filename = `${props.tokenData.owner.email}_${ownerID}/${props.selectedMerchantData.display_name}_${props.selectedMerchantData.id}/VerificationDocument/passport/VerificationDocumentFront`
        break;
      case '2':
        filename = `${props.tokenData.owner.email}_${ownerID}/${props.selectedMerchantData.display_name}_${props.selectedMerchantData.id}/VerificationDocument/driving_license/VerificationDocumentFront`
        break;

    }
    const imageRefVerificationDocument = ref(
      storage,
      filename
    );
    uploadBytes(imageRefVerificationDocument, imageUploadVerificationDocument)
      .then(() => { })
      .catch(() => {
        toast.error('error');
      });
  };

  const uploadVerificationImageBack = (ownerID) => {
    if (!imageUploadVerificationDocumentBack) return;

    const isJpgOrPng =
      imageUploadVerificationDocumentBack.type === 'image/jpg' ||
      imageUploadVerificationDocumentBack.type === 'image/png';

    if (!isJpgOrPng) {
      toast.error(
        'You can only upload JPG, or PNG file! VerificationDocument'
      );
      return;
    }
    // change name of folder from here
    const path = selectVerificationType === "2"
      ? `${props.tokenData.owner.email}_${ownerID}/${props.selectedMerchantData.display_name}_${props.selectedMerchantData.id}/VerificationDocument/driving_license/VerificationDocumentBack`
      : `${props.tokenData.owner.email}_${ownerID}/${props.selectedMerchantData.display_name}_${props.selectedMerchantData.id}/VerificationDocument/VerificationDocumentBack`

    const imageRefVerificationDocument = ref(
      storage,
      path
    );
    uploadBytes(
      imageRefVerificationDocument,
      imageUploadVerificationDocumentBack
    )
      .then(() => { })
      .catch(() => {
        toast.error('error');
      });
  };

  const [selectVerificationType, setSelectVerificationType] = useState('1');

  // to upload Registration Certificate image
  const [
    imageUploadRegistrationCertificate,
    setImageUploadRegistrationCertificate,
  ] = useState(null);

  const uploadRegistrationImage = (e) => {
    if (imageUploadRegistrationCertificate == null) return;
    const isJpgOrPng =
      imageUploadRegistrationCertificate.type === 'image/jpg' ||
      imageUploadRegistrationCertificate.type === 'application/pdf' ||
      imageUploadRegistrationCertificate.type === 'image/png';

    if (!isJpgOrPng) {
      toast.error(
        `You can only upload JPG, PNG, or PDF file! ${imageUploadRegistrationCertificate.type}`
      );
      return;
    }

    // change name of folder from here
    const imageRefRegistrationCertificate = ref(
      storage,
      `${props.tokenData.owner.id}/${props.selectedMerchantData.id}/${props.selectedMerchantData.id}/RegistrationCertificate`
    );
    uploadBytes(
      imageRefRegistrationCertificate,
      imageUploadRegistrationCertificate
    ).then(() => {
      toast.success('Image Uploaded');
    });
  };

  // to display tha image on the page =>
  const [imageList, setImageList] = useState([]);
  const imageListRef = ref(
    storage,
    `${props.tokenData.owner.id}/${props.selectedMerchantData.id}/VerificationDocument`
  );

  useEffect(() => {
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  //  *****************************

  // Reset Owner Info
  const RestInfo = () => {
    setMerchantDisplayName(
      `${props.tokenData.owner.first_legal_name} ${props.tokenData.owner.last_legal_name}`
    );
    setMerchantSupportEmail(`${props.tokenData.owner.email}`);
    setMerchantPhoneNumber(`${ownerPhoneNumber}`);
  };

  const [tosModalShow, setTosModalShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (props.selectedMerchantData.status === 'waiting_tos_acceptance') {
      setTosModalShow(true);
    }
  }, [props.selectedMerchantData]);

  const [documentOptions, setDocumentOptions] = useState([
    { "number": 0, "name": "ID Card (National Identity Card)", "back": true },
    { "number": 1, "name": "Passport", "back": false },
    { "number": 2, "name": "Driving license", "back": true }
  ]
  );
  // This functionis to set the option when the owner country changes
  useEffect(() => {
    const IdentityObj = IdentityDcouments.find((element) => {
      return element.country_2.toUpperCase() === ownerCountry.value
    })
    if (IdentityObj) {
      setDocumentOptions(IdentityObj.documents);
    } else {
      setDocumentOptions([{ number: 1, name: "Passport", back: false }])
    }
  }, [ownerCountry]);
  useEffect(() => {
    setSelectVerificationType(String(documentOptions[0].number));
  }, [documentOptions])

  const [isAgeValid, setIsAgeValid] = useState(false);

  const validateAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 18) {
      setIsAgeValid(false);
      return;
    }
    setIsAgeValid(true);
  }
  useEffect(() => {
    validateAge(ownerDob)
  }, [ownerDob]);

  return (Boolean(props.tokenData.owner) &&
    <>
      {/* {imageList.map((url) => {
        return <img src={url} alt={url} style={{ width: '100px' }} />;
      })} */}

      <TOSAcceptanceModal
        selectedMerchantData={props.selectedMerchantData}
        tosModalShow={tosModalShow}
        setTosModalShow={setTosModalShow}
        updateRequest={updateRequest}
        setLoading={setLoading}
        isLoading={isLoading}
      />
      <Row
        style={{
          width: '100%',
          margin: '0',
          textAlign: 'center',
          padding: '0',
        }}>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '48px',
            color: 'rgb(18, 35, 60)',
          }}>
          Correction request
        </p>
      </Row>

      <Form
        style={{
          justifyContent: 'center',
          display: 'flex',
          width: '100%',
          marginTop: '20px',
          marginBottom: '20px',
        }}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}>
        <Row
          style={{
            width: '50%',
            justifyContent: 'center',
            display: 'flex',
          }}>
          {/* Owner Data */}
          {ownerArr[0] && (
            <p style={{ fontWeight: 'bold', marginBottom: '20px' }}>
              Dear applicant, please fill in your information in the correct way for the following fields :
            </p>
          )}
          {/* First Name */}
          {ownerArr.includes('first_name') && (
            <Form.Floating className='mb-3'>
              <Form.Control
                type='text'
                name='first name'
                placeholder='first name'
                defaultValue={props.tokenData.owner.first_legal_name}
                onChange={(e) => setOwnerFirstLegalName(e.target.value)}
                required
              />
              <label>First Legal Name</label>
            </Form.Floating>
          )}

          {/* Last Name */}
          {ownerArr.includes('last_name') && (
            <Form.Floating className='mb-3'>
              <Form.Control
                type='text'
                name='lastName'
                placeholder='last name'
                defaultValue={props.tokenData.owner.last_legal_name}
                onChange={(e) => setOwnerLastLegalName(e.target.value)}
                required
              />
              <label>Last Legal Name</label>
            </Form.Floating>
          )}

          {/* DOB */}
          {ownerArr.includes('dob') && (
            <Form.Floating className='mb-3'>
              <Form.Control
                type='date'
                name='dob'
                placeholder='Date of Birth'
                defaultValue={ownerDob}
                onChange={(e) => setOwnerDob(e.target.value)}
                required
                isInvalid={!isAgeValid}
              />
              <Form.Control.Feedback type='invalid'>The entered age must be 18 or older!</Form.Control.Feedback>
              <label>Date of Birth</label>
            </Form.Floating>
          )}

          {/* Email */}
          {ownerArr.includes('email') && (
            <Form.Floating className='mb-3'>
              <Form.Control
                type='email'
                name='email'
                placeholder='email'
                value={props.tokenData.owner.email}
                disabled={true}
                required
              />
              <label>Email</label>
            </Form.Floating>
          )}

          {/* Verification Document */}
          {/* Note to Ward: This has to be modied later */}
          {ownerArr.includes('document') && (
            // {(
            <>
              {/* <Row>
                <label>
                  Verification Document
                  <span style={{ fontSize: '12px' }}>
                    {' '}
                    "National Id, It must be in English"
                  </span>
                </label>
                <Form.Select
                  size='sm'
                  style={{ marginLeft: '10px' }}
                  onChange={(e) => setSelectVerificationType(e.target.value)}>
                  <option value={'1'}>National Id</option>
                  <option value={'2'}>Passport</option>
                  <option value={'3'}>Driving License</option>
                </Form.Select>
              </Row> */}
              {/* Country of Issuing The Verification Document */}
              <label>Country of Issuing</label>
              <Form.Floating className='mb-3'>
                <CountrySelector
                  country={ownerCountry}
                  setCountry={setOwnerCountry}
                  // countryChange={ownerCountryChange}
                  required
                />
              </Form.Floating>
              <Row style={{ width: '99%' }}>
                <label>Verification Document</label>
                <Form.Select
                  style={{ marginLeft: '10px' }}
                  onChange={(e) => { setSelectVerificationType(e.target.value) }}
                  value={selectVerificationType}
                >
                  {documentOptions.map((element) => (
                    element.number < 3 &&
                    <option key={element.number} value={String(element.number)}>
                      {element.name}
                    </option>
                  ))}
                </Form.Select>
              </Row>

              <Row>
                {/* Verification Document */}

                {selectVerificationType === '0' ? (
                  <>
                    <Form.Group controlId='formFile' className='mb-3'>
                      <Row style={{ marginTop: '10px' }}>
                        <Col style={{ width: '100%' }}>
                          <label style={{ fontSize: '12px' }}>
                            National Id (Front Clear)
                          </label>
                          <Form.Control
                            type='file'
                            accept='.png, .jpg'
                            onChange={(e) => {
                              setImageUploadVerificationDocument(
                                e.target.files[0]
                              );
                            }}
                            required
                          />
                        </Col>

                        <Col style={{ width: '100%' }}>
                          <label style={{ fontSize: '12px' }}>
                            National Id (Back Clear)
                          </label>
                          <Form.Control
                            type='file'
                            accept='.png, .jpg '
                            onChange={(e) => {
                              setImageUploadVerificationDocumentBack(
                                e.target.files[0]
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                ) : selectVerificationType === '1' ? (
                  <>
                    <Form.Group controlId='formFile' className='mb-3'>
                      <label>Verification Document</label>
                      <span style={{ fontSize: '12px' }}> "Passport"</span>
                      <Row>
                        <Col lg={10}>
                          <Form.Control
                            type='file'
                            accept='.png, .jpg'
                            onChange={(e) => {
                              setImageUploadVerificationDocument(
                                e.target.files[0]
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group controlId='formFile' className='mb-3'>
                      <label>Verification Document</label>
                      <span style={{ fontSize: '12px' }}>
                        {' '}
                        "Driving License"
                      </span>
                      <Row>
                        <Col lg={10}>
                          <Form.Control
                            type='file'
                            accept='.png, .jpg'
                            onChange={(e) => {
                              setImageUploadVerificationDocument(
                                e.target.files[0]
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                )}
              </Row>
            </>
          )}

          {/* Country */}
          {(ownerArr.includes('country') || ownerArr.includes('city')) && (
            <>
              <Form.Floating className='mb-3'>
                <label>Country of Residence</label>
                <CountrySelector
                  country={ownerCountry}
                  setCountry={setOwnerCountry}
                  countryChange={ownerCountryChange}
                  required
                />
              </Form.Floating>

              {/* City */}

              {ownerCitiesOptions[0] ? (
                <Form.Floating className='mb-3'>
                  <Form.Select
                    className='mb-3'
                    aria-label='city'
                    onChange={(e) => setOwnerCity(e.target.value)}>
                    {ownerCitiesOptions.map((item) => (
                      <option
                        key={item.name}
                        selected={ownerCity === item.name ? true : false}
                        value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <label>City</label>
                </Form.Floating>
              ) : (
                <></>
              )}
            </>
          )}

          {/* Owner Address  */}
          {ownerArr.includes('line1') && (
            <Form.Floating className='mb-3'>
              <Form.Control
                type='text'
                name='address'
                placeholder='Address'
                onChange={(e) => setOwnerAddress(e.target.value)}
                required
              />
              <label>Address</label>
            </Form.Floating>
          )}

          {/* Owner Postal Code  */}
          {ownerArr.includes('postal_code') && (
            <Form.Floating className='mb-3'>
              <Form.Control
                type='number'
                name='postal_code'
                placeholder='postal_code'
                onChange={(e) => setOwnerPostalCode(e.target.value)}
              />
              <label>Postal Code / Zip Code</label>
            </Form.Floating>
          )}

          {/* Phone Number */}
          {ownerArr.includes('phone') && (
            <Row>
              <label style={{ marginBottom: '10px' }}>Phone Number</label>
              <PhoneNumberInput
                phoneNumber={ownerPhoneNumber}
                setPhoneNumber={setPhoneNumber}
                required
              />
            </Row>
          )}

          {/* Merchant */}
          {MerchantArr[0] && (
            <p style={{ fontWeight: 'bold', marginBottom: '20px' }}>
              Fill in the Business Information
            </p>
          )}
          {/* Display Name */}
          {MerchantArr.includes('name') && (
            <Form.Floating className='mb-3'>
              <Form.Control
                type='text'
                name='display_name'
                placeholder='Display Name'
                defaultValue={props.selectedMerchantData.display_name}
                onChange={(e) => setMerchantDisplayName(e.target.value)}
                required
              />
              <label>Display Name</label>
            </Form.Floating>
          )}

          {/* Support Email */}
          {MerchantArr.includes('support_email') && (
            <Form.Floating className='mb-3'>
              <Form.Control
                type='email'
                name='support_email'
                placeholder='Support Email'
                defaultValue={props.selectedMerchantData.support_email}
                onChange={(e) => setMerchantSupportEmail(e.target.value)}
                required
              />
              <label>Support Email</label>
            </Form.Floating>
          )}

          {/* Merchant Phone Number */}
          {MerchantArr.includes('support_phone') && (
            <Row style={{ marginBottom: '20px' }}>
              <label style={{ marginBottom: '10px' }}>Support Phone</label>
              <PhoneNumberInput
                phoneNumber={merchantPhoneNumber}
                setPhoneNumber={setMerchantPhoneNumber}
                required
              />
            </Row>
          )}

          {businessType === 'company' && (
            <>
              {/* registration certificate */}
              {MerchantArr.includes('registration_certificate') && (
                <Form.Group controlId='formFile' className='mb-3'>
                  <label>Registration Certificate</label>
                  <Row>
                    <Col lg={10}>
                      <Form.Control
                        type='file'
                        accept='.png, .jpg, .pdf'
                        name='registration_certificate'
                        placeholder='registration_certificate'
                        defaultValue={
                          props.tokenData.owner.registration_certificate
                        }
                        onChange={(e) => {
                          setImageUploadRegistrationCertificate(
                            e.target.files[0]
                          );
                        }}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
              )}

              {/* Registration Number */}
              {MerchantArr.includes('registration_number') && (
                <Form.Floating className='mb-3'>
                  <Form.Control
                    type='text'
                    name='registration_number'
                    placeholder='Registration Number'
                    defaultValue={
                      props.selectedMerchantData.registration_number
                    }
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                    required
                  />
                  <label>Registration Number</label>
                </Form.Floating>
              )}

              {/* Legal Name */}
              {MerchantArr.includes('name') && (
                <Form.Floating className='mb-3'>
                  <Form.Control
                    type='text'
                    name='legal_name'
                    placeholder='Legal Name'
                    defaultValue={props.selectedMerchantData.legal_name}
                    onChange={(e) => setMerchantLegalName(e.target.value)}
                    required
                  />
                  <label>Legal Name</label>
                </Form.Floating>
              )}

              {/* Company Structure */}
              {MerchantArr.includes('company_structure') && (
                <Form.Floating className='mb-3'>
                  <Form.Select
                    aria-label='company_structure'
                    onChange={(e) => setCompanyStructure(e.target.value)}>
                    {/* Sole Proprietorship */}
                    <option value='Sole Proprietorship'>
                      Sole Proprietorship
                    </option>
                    {/* LLC */}
                    <option
                      selected={
                        props.selectedMerchantData.company_structure === 'LLC'
                          ? true
                          : false
                      }
                      value='LLC'>
                      LLC
                    </option>
                    {/* Corporation */}
                    <option
                      selected={
                        props.selectedMerchantData.company_structure ===
                          'Corporation'
                          ? true
                          : false
                      }
                      value='Corporation'>
                      Corporation
                    </option>
                    {/* Partnership */}
                    <option
                      selected={
                        props.selectedMerchantData.company_structure ===
                          'Partnership'
                          ? true
                          : false
                      }
                      value='Partnership'>
                      Partnership
                    </option>
                  </Form.Select>
                  <label>Company Structure</label>
                </Form.Floating>
              )}

              {/* Merchant Website Link */}
              {MerchantArr.includes('website_link') && (
                <Form.Floating className='mb-3'>
                  <Form.Control
                    type='url'
                    name='website_link'
                    placeholder='website_link'
                    defaultValue={props.selectedMerchantData.website_link}
                    onChange={(e) => setMerchantWebsiteLink(e.target.value)}
                  />
                  <label>Wepsite Link</label>
                </Form.Floating>
              )}

              {/* Merchant Country */}
              {MerchantArr.includes('country') && (
                <Form.Floating className='mb-3'>
                  <CountrySelector
                    country={merchantCountry}
                    setCountry={setMerchantCountry}
                    countryChange={merchantCountryChange}
                  />
                </Form.Floating>
              )}

              {/* Merchant City */}
              {MerchantArr.includes('city') && (
                <>
                  {merchantCitiesOptions[0] ? (
                    <Form.Floating className='mb-3'>
                      <Form.Select
                        className='mb-3'
                        aria-label='merchantcity'
                        onChange={(e) => setMerchantCity(e.target.value)}>
                        {merchantCitiesOptions.map((item) => (
                          <option
                            key={item.name}
                            selected={merchantCity === item.name ? true : false}
                            value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <label>City</label>
                    </Form.Floating>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {/* Merchant Address Line1 */}
              {MerchantArr.includes('line1') && (
                <Form.Floating className='mb-3'>
                  <Form.Control
                    type='text'
                    name='line1'
                    placeholder='Line1'
                    defaultValue={props.selectedMerchantData.line1}
                    onChange={(e) => setMerchantLine1(e.target.value)}
                    required
                  />
                  <label>Line1</label>
                </Form.Floating>
              )}

              {/* Merchant Address Line2 */}
              {MerchantArr.includes('line2') && (
                <Form.Floating className='mb-3'>
                  <Form.Control
                    type='text'
                    name='line2'
                    placeholder='Line2'
                    defaultValue={props.selectedMerchantData.line2}
                    onChange={(e) => setMerchantLine2(e.target.value)}
                  />
                  <label>Line2</label>
                </Form.Floating>
              )}

              {/* Merchant Postal Code */}
              {MerchantArr.includes('postal_code') && (
                <Form.Floating className='mb-3'>
                  <Form.Control
                    type='text'
                    name='postal_code'
                    placeholder='Postal Code'
                    defaultValue={props.selectedMerchantData.postal_code}
                    onChange={(e) => setMerchantPostalCode(e.target.value)}
                    required
                  />
                  <label>Postal Code</label>
                </Form.Floating>
              )}
            </>
          )}

          {/* Submit Button */}
          <Row style={{ margin: '0' }}>
            <Button
              className='Loginbutton Lbutton'
              // onClick={handleSubmit}
              type='submit'
              style={{ marginTop: '20px', marginBottom: '20px' }}>
              Submit
            </Button>
          </Row>
        </Row>
      </Form>
    </>
  );
}
