/** @format */

import React, { useState, useEffect } from "react";
import TOSAcceptanceModal from "../components/modals/TOSAcceptanceModal";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { isValidPhoneNumber } from "react-phone-number-input";
import { GrPowerReset } from "react-icons/gr";
import { toast } from "react-toastify";
import { storage } from "../firebase";
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";
import AxiosInstanceUserServer from "../components/axios/AxiosInstanceUserServer";
import MerchantsListModal from "../components/modals/MerchantsListModal";
import CountrySelector from "../components/CountrySelector";
import PhoneNumberInput from "../components/PhoneNumberInput";
import IdentityDcoumentData from "../IdentityDocuments.json";

const IdentityDcouments = JSON.parse(JSON.stringify(IdentityDcoumentData));

function OnBoarding(props) {
  // Function to clear complete cache data
  useEffect(() => {
    clearCacheData();
    if (window.location.pathname === "/") {
      window.location.replace("/onboarding");
    }
  }, []);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  // Loading in Button
  const [isLoading, setLoading] = useState(false);
  const [merchantsListModalShow, setMerchantsListModalShow] = useState(false);
  const [tosModalShow, setTosModalShow] = useState(false);

  useEffect(() => {
    pageRedirect();
  }, []);
  function pageRedirect() {
    if (props.selectedMerchant) {
      if (
        props.selectedMerchant.status !== "waiting_business_scheme" ||
        props.selectedMerchant.status !== "waiting_information" ||
        props.selectedMerchant.status !== "request_correction"
      ) {
        setMerchantsListModalShow(true);
      }
    }
  }

  // States for the Owner Data
  //1 First Legal Name
  const [ownerFirstLegalName, setOwnerFirstLegalName] = useState();
  //2 Last Legal Name
  const [ownerLastLegalName, setOwnerLastLegalName] = useState();
  //3 DOB
  const [ownerDob, setOwnerDob] = useState("");
  function formatOwnerDOB() {
    if (props.tokenData.owner.dob) {
      const months = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let date = props.tokenData.owner.dob.split(" ");
      let dateString = `${date[3]}-${
        months.indexOf(date[2]) <= 9
          ? "0" + months.indexOf(date[2])
          : months.indexOf(date[2])
      }-${date[1]}`;
      setOwnerDob(dateString);
    }
  }
  //4 Country of Issuing ID
  const [ownerCountry, setOwnerCountry] = useState({
    value: "JO",
    label: "Jordan",
  });
  //5 Country of residence
  const [residenceCountry, setResidenceCountry] = useState({
    value: "JO",
    label: "Jordan",
  });
  //6 City
  const [ownerCity, setOwnerCity] = useState("Amman");
  const [ownerCitiesOptions, setOwnerCitiesOptions] = useState([
    { name: "Amman" },
    { name: "Irbid" },
    { name: "Az Zarqa'" },
    { name: "Al `Aqabah" },
    { name: "As Salt" },
    { name: "Al Mafraq" },
    { name: "Jarash" },
    { name: "Ma`An" },
    { name: "At Tafilah" },
    { name: "Al Karak" },
    { name: "Madaba" },
    { name: "`Ajlun" },
    { name: "At Turrah" },
    { name: "Sakib" },
  ]);

  //7 Owner Address
  const [ownerAddress, setOwnerAddress] = useState("");

  //8 Owner postal code
  const [ownerPostalCode, setOwnerPostalCode] = useState("");

  //9 Phone Number
  const [ownerPhoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    formatOwnerDOB();
    getCountryLabel(props.tokenData.owner.country, 1);
    ownerCountryChange(
      props.tokenData.owner ? props.tokenData.owner.country : "JO"
    );
  }, [props.tokenData]);
  const [stripeOwnerID, setStripeOwnerID] = useState("");

  // States for the Merchant Data
  const [businessType, setBusinessType] = useState("individual");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [merchantLegalName, setMerchantLegalName] = useState("");
  const [merchantDisplayName, setMerchantDisplayName] = useState("");
  const [StatmentDescriptor, setStatmentDescriptor] = useState("");
  const [merchantSupportEmail, setMerchantSupportEmail] = useState("");
  const [merchantPhoneNumber, setMerchantPhoneNumber] = useState("");
  const [companyStructure, setCompanyStructure] = useState(
    "Sole Proprietorship"
  );
  const [merchantWebsiteLink, setMerchantWebsiteLink] = useState("");
  const [merchantCountry, setMerchantCountry] = useState({
    value: "JO",
    label: "Jordan",
  });
  const [merchantCity, setMerchantCity] = useState("Amman");
  const [merchantCitiesOptions, setMerchantCitiesOptions] = useState([
    { name: "Amman" },
    { name: "Irbid" },
    { name: "Az Zarqa'" },
    { name: "Al `Aqabah" },
    { name: "As Salt" },
    { name: "Al Mafraq" },
    { name: "Jarash" },
    { name: "Ma`An" },
    { name: "At Tafilah" },
    { name: "Al Karak" },
    { name: "Madaba" },
    { name: "`Ajlun" },
    { name: "At Turrah" },
    { name: "Sakib" },
  ]);
  const [merchantLine1, setMerchantLine1] = useState("");
  const [merchantLine2, setMerchantLine2] = useState("");
  const [merchantPostalCode, setMerchantPostalCode] = useState("");

  // GET the Country Label Full Name by Giving the ISO2 Name
  function getCountryLabel(value, form) {
    const options = {
      method: "GET",
      url: `https://api.api-ninjas.com/v1/country?name=${value}`,
      headers: {
        "X-Api-Key": "xDoicZeqn2WcLBx3x18fyQ==SqNOmz757IG4BhZF",
      },
    };
    axios.request(options).then((res) => {
      if (form === 1 && res.data.length > 0) {
        res.data[0].name === "Palestine, State Of"
          ? setResidenceCountry({
              value: value,
              label: "Palestine, not Israel",
            })
          : setResidenceCountry({
              value: value,
              label: res.data[0].name,
            });
      } else if (form === 2) {
        res.data[0].name === "Palestine, State Of"
          ? setMerchantCountry({
              value: value,
              label: "Palestine, not Israel",
            })
          : setMerchantCountry({
              value: value,
              label: res.data[0].name,
            });
      }
    });
  }

  const [documentOptions, setDocumentOptions] = useState([
    { number: 0, name: "ID Card (National Identity Card)", back: true },
    { number: 1, name: "Passport", back: false },
    { number: 2, name: "Driving license", back: true },
  ]);
  // This functionis to set the option when the owner country changes
  useEffect(() => {
    const IdentityObj = IdentityDcouments.find((element) => {
      return element.country_2.toUpperCase() === ownerCountry.value;
    });
    if (IdentityObj) {
      setDocumentOptions(IdentityObj.documents);
    } else {
      setDocumentOptions([{ number: 1, name: "Passport", back: false }]);
    }
  }, [ownerCountry]);
  useEffect(() => {
    setSelectVerificationType(String(documentOptions[0].number));
  }, [documentOptions]);

  // Change the Cities Options for the Owner City Based on the Selected Country by Sending a request to Ninja API
  function ownerCountryChange(givingCountry) {
    if (givingCountry === "PS") {
      setOwnerCitiesOptions([
        { name: "Jerusalem" },
        { name: "Bethlehem" },
        { name: "Beit Jala" },
        { name: "Beit Sahour" },
        { name: "Hebron" },
        { name: "Sabastia" },
        { name: "Jericho" },
        { name: "Ramallah & El-Bireh" },
        { name: "Nablus" },
        { name: "Tulkarem" },
        { name: "Jenin" },
        { name: "Gaza" },
        { name: "Rafah" },
        { name: "Khan Younis" },
        { name: "Tel Aviv-Yafo" },
        { name: "Haifa" },
        { name: "Rishon Leziyyon" },
        { name: "Petah Tiqwa" },
        { name: "Ashdod" },
        { name: "Netanya" },
        { name: "Beersheba" },
        { name: "Bene Beraq" },
        { name: "Holon" },
        { name: "Ramat Gan" },
        { name: "Bat Yam" },
        { name: "Ashqelon" },
        { name: "Rehovot" },
        { name: "Bet Shemesh" },
        { name: "Kefar Sava" },
        { name: "Herzliyya" },
        { name: "Nazareth" },
        { name: "Ra`Ananna" },
        { name: "Ramla" },
        { name: "Giv`Atayim" },
        { name: "Hod Hasharon" },
        { name: "Qiryat Ata" },
        { name: "Rosh Ha`Ayin" },
        { name: "Umm El Fahm" },
        { name: "Nes Ziyyona" },
        { name: "Ramat Hasharon" },
        { name: "Karmi'el" },
        { name: "Qiryat Ono" },
        { name: "Qiryat Bialik" },
      ]);
      setOwnerCity("Jerusalem");
      getCountryLabel(givingCountry, 1);
    } else {
      const options = {
        method: "GET",
        url: `https://api.api-ninjas.com/v1/city?country=${
          givingCountry.value ? givingCountry.value : givingCountry
        }&limit=1000`,
        headers: {
          "X-Api-Key": "xDoicZeqn2WcLBx3x18fyQ==SqNOmz757IG4BhZF",
        },
      };
      axios.request(options).then((res) => {
        setOwnerCitiesOptions(res.data);
        // Set the City State Value Based on the New Selected City
        setOwnerCity(
          res.data[
            ownerCitiesOptions.findIndex(
              (option) => option.name === ownerCity
            ) > res.data.length
              ? 0
              : ownerCitiesOptions.findIndex(
                  (option) => option.name === ownerCity
                )
          ].name
        );
        getCountryLabel(givingCountry, 1);
      });
    }
  }

  // Change the Cities Options for the Merchant City Based on the Selected Country by Sending a request to Ninja API
  function merchantCountryChange(givingCountry) {
    if (givingCountry === "PS") {
      setMerchantCitiesOptions([
        { name: "Jerusalem" },
        { name: "Bethlehem" },
        { name: "Beit Jala" },
        { name: "Beit Sahour" },
        { name: "Hebron" },
        { name: "Sabastia" },
        { name: "Jericho" },
        { name: "Ramallah & El-Bireh" },
        { name: "Nablus" },
        { name: "Tulkarem" },
        { name: "Jenin" },
        { name: "Gaza" },
        { name: "Rafah" },
        { name: "Khan Younis" },
        { name: "Tel Aviv-Yafo" },
        { name: "Haifa" },
        { name: "Rishon Leziyyon" },
        { name: "Petah Tiqwa" },
        { name: "Ashdod" },
        { name: "Netanya" },
        { name: "Beersheba" },
        { name: "Bene Beraq" },
        { name: "Holon" },
        { name: "Ramat Gan" },
        { name: "Bat Yam" },
        { name: "Ashqelon" },
        { name: "Rehovot" },
        { name: "Bet Shemesh" },
        { name: "Kefar Sava" },
        { name: "Herzliyya" },
        { name: "Nazareth" },
        { name: "Ra`Ananna" },
        { name: "Ramla" },
        { name: "Giv`Atayim" },
        { name: "Hod Hasharon" },
        { name: "Qiryat Ata" },
        { name: "Rosh Ha`Ayin" },
        { name: "Umm El Fahm" },
        { name: "Nes Ziyyona" },
        { name: "Ramat Hasharon" },
        { name: "Karmi'el" },
        { name: "Qiryat Ono" },
        { name: "Qiryat Bialik" },
      ]);
      setMerchantCity("Jerusalem");
      getCountryLabel(givingCountry, 2);
    } else {
      const options = {
        method: "GET",
        url: `https://api.api-ninjas.com/v1/city?country=${
          givingCountry.value ? givingCountry.value : givingCountry
        }&limit=1000`,
        headers: {
          "X-Api-Key": "xDoicZeqn2WcLBx3x18fyQ==SqNOmz757IG4BhZF",
        },
      };
      axios.request(options).then((res) => {
        setMerchantCitiesOptions(res.data);
        // Set the City State Value Based on the New Selected City
        setMerchantCity(
          res.data[
            merchantCitiesOptions.findIndex(
              (option) => option.name === ownerCity
            ) > res.data.length
              ? 0
              : merchantCitiesOptions.findIndex(
                  (option) => option.name === ownerCity
                )
          ].name
        );
        getCountryLabel(givingCountry, 2);
      });
    }
  }
  // Submit the Form
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    var reg = /^[a-zA-Z0-9._ -]+$/;

    // The regex only allow 3 to 22 characters, the last character cannot be a space, and must inlude at least one alphabetical character
    // Allowed special characters are-->  $@$!%*?&#-_. +
    var regDisplayName =
      /^(?=.*[a-zA-Z])(?!.*\s$)(?=.{5,22}$)[a-zA-Z0-9$@$!%?&#_. +\u0600-\u06FF]+$/;

    var regStatementDescriptor =
      /^(?!.*\b(?:http|www|\.com|\.net|\.org|example)\b)(?=.{5,18}$)[a-zA-Z0-9 ]*(?<! )$/;

    // Check if the Form is Valid
    if (form.checkValidity()) {
      if (businessType === "individual") {
        setMerchantCountry({ ...residenceCountry });
        setMerchantCity(ownerCity);
      }
      if (!reg.test(ownerFirstLegalName) || !reg.test(ownerLastLegalName)) {
        toast.error(
          "Please enter first name and last name only English characters"
        );
        return;
      }
      if (!regDisplayName.test(merchantDisplayName)) {
        toast.error(
          "Please enter 5 to 22 characters display name without / or \\, and without white spaces at the end"
        );
        return;
      }
      if (!regStatementDescriptor.test(StatmentDescriptor)) {
        toast.error(
          "Please enter 5 to 22 characters statement descriptor without / or \\, and without white spaces at the end"
        );
        return;
      }

      if (imageUploadVerificationDocument === null) {
        toast.error("Please Upload Your Verification Document");
        return;
      } else {
        const isJpgOrPng =
          imageUploadVerificationDocument.type === "image/jpg" ||
          imageUploadVerificationDocument.type === "image/png";
        if (!isJpgOrPng) {
          toast.error(
            "You can only upload JPG, or PNG file! VerificationDocument"
          );
          return;
        }
      }

      if (
        !imageUploadVerificationDocumentBack &&
        selectVerificationType === "0"
      ) {
        toast.error("Please Upload Your Verification Document");
        return;
      }

      if (
        businessType === "company" &&
        imageUploadRegistrationCertificate === null
      ) {
        toast.error("Please Upload Your Registration Certificate");
        return;
      }
      if (!isValidPhoneNumber(ownerPhoneNumber)) {
        toast.error("Invalid Phone Number it Should be Like +XXX XX XXX XXXX");
        return;
      }
      const response = await AxiosInstanceUserServer.get(
        `/retrieve-owner-id/${props.selectedMerchantData.id}`
      );
      if (!response || !response.data || !response.data.OwnerID) return;
      setStripeOwnerID(response.data.OwnerID);
      uploadVerificationImage(response.data.OwnerID); // to upload Verification Image
      if (imageUploadVerificationDocumentBack && selectVerificationType === "0")
        uploadVerificationImageBack(response.data.OwnerID); // to upload Verification Image Back

      if (imageUploadRegistrationCertificate !== null)
        // to upload Registration Image
        uploadRegistrationImage();

      setTosModalShow(true);
    } else {
      event.stopPropagation();
    }
    setValidated(true);
  };
  // Send Request to PUT the Owner & Merchant Information
  const updateRequest = async () => {
    let phone_number = ownerPhoneNumber.slice(1); // Delete the First Two Characters '+ '
    const ownerObject = {
      first_legal_name: ownerFirstLegalName,
      last_legal_name: ownerLastLegalName,
      dob: ownerDob,
      phone_number: phone_number,
      country: ownerCountry.value,
      city: ownerCity,
      address: ownerAddress,
      postal_code: ownerPostalCode,
      country_of_residence: residenceCountry.value,
    };
    if (stripeOwnerID !== props.selectedMerchantData.owner_id) {
      try {
        await AxiosInstanceUserServer.post(
          `/update-person/${props.tokenData.owner.id}/${stripeOwnerID}/${props.selectedMerchantData.id}`,
          ownerObject
        );
      } catch (error) {
        toast.error("Something Went Wrong, Please Try Again");
        setLoading(false);
      }
    } else {
      try {
        await AxiosInstanceUserServer.put(
          `/owner/update/${stripeOwnerID}`,
          ownerObject
        );
      } catch (error) {
        console.error("Error trying to update owner:", error);
        let errorMessage = "Something Went Wrong, Please Try Again";
        if (error.code === 400) {
          errorMessage = error.response.data;
        }
        toast.error(errorMessage);
      }
    }

    let merchant_phone_number = merchantPhoneNumber.slice(1); // Delete the First Two Characters '+ '
    try {
      const res = await AxiosInstanceUserServer.put(
        `/merchant/update/${props.selectedMerchantData.id}`,
        {
          business_type: businessType,
          display_name: merchantDisplayName,
          legal_name: merchantLegalName,
          registration_number:
            registrationNumber === "" ? null : registrationNumber,
          company_structure:
            businessType === "company" ? companyStructure : null,
          support_email: merchantSupportEmail.trim(),
          phone_number:
            merchant_phone_number === "" ? null : merchant_phone_number,
          website_link: merchantWebsiteLink === "" ? null : merchantWebsiteLink,
          country: merchantCountry.value,
          city: merchantCity,
          line1: merchantLine1,
          line2: merchantLine2,
          postal_code: merchantPostalCode === "" ? null : merchantPostalCode,
          statement_descriptor: StatmentDescriptor,
          verification_type: selectVerificationType,
        }
      );
      if (res) {
        window.location.replace(`/pending-verification?id=${res.data.id}`);
      }
    } catch (error) {
      console.error("Error trying to update merchant:", error);
      let errorMessage = "Something Went Wrong, Please Try Again";
      if (error.code === 400) {
        errorMessage = error.response.data;
      }
      toast.error(errorMessage);
    }
  };

  // to upload Verification Document image watch this video => https://www.youtube.com/watch?v=YOAeBSCkArA

  const [selectVerificationType, setSelectVerificationType] = useState("1");
  const [imageUploadVerificationDocument, setImageUploadVerificationDocument] =
    useState();
  const [
    imageUploadVerificationDocumentBack,
    setImageUploadVerificationDocumentBack,
  ] = useState();

  const uploadVerificationImage = (ownerID) => {
    if (!imageUploadVerificationDocument) return;

    const isJpgOrPng =
      imageUploadVerificationDocument.type === "image/jpg" ||
      imageUploadVerificationDocument.type === "image/png";

    if (!isJpgOrPng) {
      toast.error("You can only upload JPG, or PNG file! VerificationDocument");
      return;
    }
    // change name of folder from here
    // Generate the filename based on the selected document type
    let filename = "";
    //  value={'1'} => National Id / value={'2'} => Passport / value={'3'} => Driving License
    switch (selectVerificationType) {
      case "0":
        filename = `${props.tokenData.owner.email}_${ownerID}/${merchantDisplayName}_${props.selectedMerchantData.id}/VerificationDocument/VerificationDocumentFront`;
        break;
      case "1":
        filename = `${props.tokenData.owner.email}_${ownerID}/${merchantDisplayName}_${props.selectedMerchantData.id}/VerificationDocument/passport/VerificationDocumentFront`;
        break;
      case "2":
        filename = `${props.tokenData.owner.email}_${ownerID}/${merchantDisplayName}_${props.selectedMerchantData.id}/VerificationDocument/driving_license/VerificationDocumentFront`;
        break;
    }
    // Create a reference to the storage path based on the filename
    const imageRefVerificationDocument = ref(storage, filename);
    uploadBytes(imageRefVerificationDocument, imageUploadVerificationDocument)
      .then(() => {
        // toast.success('Image Uploaded');
      })
      .catch(() => {
        toast.error(
          "Something Went Wrong When Uploading Your Verification Document, Please Try Again!"
        );
      });
  };

  const uploadVerificationImageBack = (ownerID) => {
    if (!imageUploadVerificationDocumentBack) return;

    const isJpgOrPng =
      imageUploadVerificationDocumentBack.type === "image/jpg" ||
      imageUploadVerificationDocumentBack.type === "image/png";

    if (!isJpgOrPng) {
      toast.error(
        "You can only upload JPG , or PNG file! VerificationDocument"
      );
      return;
    }
    const path =
      selectVerificationType === "2"
        ? `${props.tokenData.owner.email}_${ownerID}/${merchantDisplayName}_${props.selectedMerchantData.id}/VerificationDocument/driving_license/VerificationDocumentBack`
        : `${props.tokenData.owner.email}_${ownerID}/${merchantDisplayName}_${props.selectedMerchantData.id}/VerificationDocument/VerificationDocumentBack`;
    // change name of folder from here
    const imageRefVerificationDocument = ref(storage, path);
    uploadBytes(
      imageRefVerificationDocument,
      imageUploadVerificationDocumentBack
    )
      .then(() => {
        // toast.success('Image Uploaded');
      })
      .catch(() => {
        toast.error(
          "Something Went Wrong When Uploading Your Verification Document, Please Try Again!"
        );
      });
  };

  // *************************************************

  // to upload Registration Certificate image
  const [
    imageUploadRegistrationCertificate,
    setImageUploadRegistrationCertificate,
  ] = useState(null);

  const uploadRegistrationImage = (e) => {
    if (imageUploadRegistrationCertificate == null) return;
    const isJpgOrPng =
      imageUploadRegistrationCertificate.type === "image/jpg" ||
      imageUploadRegistrationCertificate.type === "application/pdf" ||
      imageUploadRegistrationCertificate.type === "image/png";

    if (!isJpgOrPng) {
      toast.error(
        `You can only upload JPG, PNG, or PDF file! ${imageUploadRegistrationCertificate.type}`
      );
      return;
    }

    // change name of folder from here
    const imageRefRegistrationCertificate = ref(
      storage,
      `${props.tokenData.owner.id}/${props.selectedMerchantData.id}/${merchantDisplayName}/RegistrationCertificate`
    );
    uploadBytes(
      imageRefRegistrationCertificate,
      imageUploadRegistrationCertificate
    )
      .then(() => {})
      .catch(() => {
        toast.error(
          "Something Went Wrong When Uploading Your Merchant Registration Certificate, Please Try Again!"
        );
      });
  };

  const [isAgeValid, setIsAgeValid] = useState(false);

  const validateAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 18 || age > 120) {
      setIsAgeValid(false);
      return;
    }
    setIsAgeValid(true);
  };
  useEffect(() => {
    validateAge(ownerDob);
  }, [ownerDob]);

  // to display tha image on the page =>
  // const [imageList, setImageList] = useState([]);
  // const imageListRef = ref(
  //   storage,
  //   `${props.tokenData.owner.id}/${props.selectedMerchantData.id}/`
  // );

  // useEffect(() => {
  //   listAll(imageListRef).then((response) => {
  //     response.items.forEach((item) => {
  //       getDownloadURL(item).then((url) => {
  //         setImageList((prev) => [...prev, url]);
  //       });
  //     });
  //   });
  // }, []);

  // then put this code after the return
  //  {imageList.map((url) => {
  //   return <img src={url} alt={url} />;
  // })}

  //  *****************************

  // Reset Owner Info
  const RestInfo = () => {
    setMerchantDisplayName(
      `${props.tokenData.owner.first_legal_name} ${props.tokenData.owner.last_legal_name}`
    );
    setMerchantSupportEmail(`${props.tokenData.owner.email}`);
    setMerchantPhoneNumber(`${ownerPhoneNumber}`);
  };

  useEffect(() => {
    setOwnerFirstLegalName(`${props.tokenData.owner.first_legal_name}`);
    setOwnerLastLegalName(`${props.tokenData.owner.last_legal_name}`);
  }, [props.tokenData]);

  return (
    <>
      {merchantsListModalShow && (
        <MerchantsListModal
          merchantsListModalShow={merchantsListModalShow}
          setMerchantsListModalShow={setMerchantsListModalShow}
          tokenData={props.tokenData}
        />
      )}

      {/* TOS Acceptance Modal */}
      {tosModalShow && (
        <TOSAcceptanceModal
          selectedMerchantData={props.selectedMerchantData}
          tosModalShow={tosModalShow}
          setTosModalShow={setTosModalShow}
          updateRequest={updateRequest}
          setLoading={setLoading}
          isLoading={isLoading}
        />
      )}

      <Row
        style={{
          margin: "0",
          padding: "0 0 0 0",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Form
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            justifyContent: "center",
            display: "flex",
          }}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Row
            style={{
              width: "90%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {/* Owner Data */}
            <Col lg={4} md={12} sm={12}>
              <p style={{ fontWeight: "bold", marginBottom: "20px" }}>
                Fill in the Owner Information
              </p>

              {/* First Name */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="first name"
                  placeholder="first name"
                  defaultValue={props.tokenData.owner.first_legal_name}
                  onChange={(e) => setOwnerFirstLegalName(e.target.value)}
                  required
                />
                <label>First Legal Name</label>
              </Form.Floating>

              {/* Last Name */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="last name"
                  defaultValue={props.tokenData.owner.last_legal_name}
                  onChange={(e) => setOwnerLastLegalName(e.target.value)}
                  required
                />
                <label>Last Legal Name</label>
              </Form.Floating>

              {/* DOB */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="date"
                  name="dob"
                  placeholder="Date of Birth"
                  defaultValue={ownerDob}
                  onChange={(e) => setOwnerDob(e.target.value)}
                  required
                  isInvalid={!isAgeValid}
                />
                <Form.Control.Feedback type="invalid">
                  The entered age must be 18 or older!
                </Form.Control.Feedback>
                <label>Date of Birth</label>
              </Form.Floating>

              {/* Email */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="email"
                  defaultValue={props.tokenData.owner.email}
                  disabled={true}
                  required
                />
                <label>Email</label>
              </Form.Floating>

              {/* Country of Issuing The Verification Document */}
              <label>Country of Issuing</label>
              <Form.Floating className="mb-3">
                <CountrySelector
                  country={ownerCountry}
                  setCountry={setOwnerCountry}
                  // countryChange={ownerCountryChange}
                  required
                />
              </Form.Floating>

              <Row style={{ width: "99%" }}>
                <label>Verification Document</label>
                <Form.Select
                  value={selectVerificationType}
                  style={{ marginLeft: "10px" }}
                  onChange={(e) => {
                    setSelectVerificationType(e.target.value);
                  }}
                >
                  {documentOptions.map(
                    (element) =>
                      element.number < 3 && (
                        <option
                          key={element.number}
                          value={String(element.number)}
                        >
                          {element.name}
                        </option>
                      )
                  )}
                </Form.Select>
              </Row>

              {/* Verification Document */}
              <Row>
                {selectVerificationType === "0" ? (
                  <>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Row style={{ marginTop: "10px" }}>
                        <Col style={{ width: "100%" }}>
                          <label style={{ fontSize: "12px" }}>
                            National Id (Front Clear)
                          </label>
                          <Form.Control
                            size="sm"
                            type="file"
                            accept=".png, .jpg "
                            onChange={(e) => {
                              setImageUploadVerificationDocument(
                                e.target.files[0]
                              );
                            }}
                            required
                          />
                        </Col>

                        <Col style={{ width: "100%" }}>
                          <label style={{ fontSize: "12px" }}>
                            National Id (Back Clear)
                          </label>
                          <Form.Control
                            size="sm"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => {
                              setImageUploadVerificationDocumentBack(
                                e.target.files[0]
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                ) : selectVerificationType === "1" ? (
                  <>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Row style={{ marginTop: "10px" }}>
                        <Col lg={12}>
                          <label>Verification Document</label>
                          <span style={{ fontSize: "12px" }}> "Passport"</span>
                          <Form.Control
                            size="sm"
                            type="file"
                            accept=".png, .jpg"
                            onChange={(e) => {
                              setImageUploadVerificationDocument(
                                e.target.files[0]
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Row style={{ marginTop: "10px" }}>
                        <Col lg={12}>
                          <label>Verification Document</label>
                          <span style={{ fontSize: "12px" }}>
                            {" "}
                            "Driving License"
                          </span>
                          <Form.Control
                            size="sm"
                            type="file"
                            accept=".png, .jpg"
                            onChange={(e) => {
                              setImageUploadVerificationDocument(
                                e.target.files[0]
                              );
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                )}
              </Row>

              {/* Country of Residence */}
              <label>Country of Residence</label>
              <Form.Floating className="mb-3">
                <CountrySelector
                  country={residenceCountry}
                  setCountry={setResidenceCountry}
                  countryChange={ownerCountryChange}
                  required
                />
              </Form.Floating>

              {/* City */}
              {ownerCitiesOptions[0] ? (
                <Form.Floating className="mb-3">
                  <Form.Select
                    className="mb-3"
                    aria-label="city"
                    onChange={(e) => setOwnerCity(e.target.value)}
                    defaultValue={ownerCity}
                  >
                    {ownerCitiesOptions.map((item, idx) => (
                      <option key={idx} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <label>City</label>
                </Form.Floating>
              ) : (
                <></>
              )}

              {/* Owner Address  */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="Address"
                  onChange={(e) => setOwnerAddress(e.target.value)}
                  required
                />
                <label>Address</label>
              </Form.Floating>

              {/* Owner Postal Code  */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="number"
                  name="postal_code"
                  placeholder="postal_code"
                  onChange={(e) => setOwnerPostalCode(e.target.value)}
                />
                <label>Postal Code / Zip Code</label>
              </Form.Floating>

              {/* Phone Number */}
              <Row>
                <label style={{ marginBottom: "10px" }}>Phone Number</label>
                <PhoneNumberInput
                  phoneNumber={ownerPhoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  required
                />
              </Row>
            </Col>
            {/* Merchant Data */}
            <Col lg={4} md={12} sm={12}>
              <Row style={{ marginBottom: "20px" }}>
                <Col>
                  <p style={{ fontWeight: "bold", paddingTop: "2px" }}>
                    Fill in the Business Information
                  </p>
                </Col>
                <Col
                  lg={4}
                  style={{ justifyContent: "right", display: "flex" }}
                >
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip style={{ fontSize: "10px" }}>
                        Auto fill from the owner Information.
                      </Tooltip>
                    }
                  >
                    <Button variant="" onClick={RestInfo}>
                      <GrPowerReset />
                      <span style={{ fontSize: "8px" }}> Auto Fill </span>
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>

              {/* Business Type */}
              <Form.Floating>
                <Form.Select
                  className="mb-3"
                  aria-label="business_type"
                  onChange={(e) => setBusinessType(e.target.value)}
                  defaultValue={
                    props.selectedMerchantData.business_type === "company"
                      ? "company"
                      : "individual"
                  }
                >
                  <option value="individual">Individual</option>
                  <option value="company">Company</option>
                </Form.Select>
                <label> Select Business Type </label>
              </Form.Floating>
              {/* Display Name */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="display_name"
                  placeholder="Display Name"
                  value={merchantDisplayName}
                  onChange={(e) => setMerchantDisplayName(e.target.value)}
                  required
                />
                <label>
                  Display Name
                  <span style={{ fontSize: "12px" }}>
                    {" "}
                    "this could be your merchant name, store name,
                    website...etc"
                  </span>
                </label>
              </Form.Floating>
              {/* Statment Descriptor */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="Statment_Descriptor"
                  placeholder="Statment Descriptor"
                  value={StatmentDescriptor}
                  onChange={(e) => setStatmentDescriptor(e.target.value)}
                  required
                />
                <label>Statment Descriptor</label>
              </Form.Floating>

              {/* Support Email */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="email"
                  name="support_email"
                  placeholder="Support Email"
                  value={merchantSupportEmail}
                  onChange={(e) => setMerchantSupportEmail(e.target.value)}
                  required
                />
                <label>Support Email</label>
              </Form.Floating>

              {/* Merchant Phone Number */}
              <Row style={{ marginBottom: "20px" }}>
                <label style={{ marginBottom: "10px" }}>Support Phone</label>
                <PhoneNumberInput
                  phoneNumber={merchantPhoneNumber}
                  setPhoneNumber={setMerchantPhoneNumber}
                  required
                />
              </Row>

              {businessType === "company" && (
                <>
                  {/* registration certificate */}
                  <Form.Group controlId="formFile" className="mb-3">
                    <label>Registration Certificate</label>
                    <Row>
                      <Col lg={10}>
                        <Form.Control
                          type="file"
                          accept=".png, .jpg, .pdf"
                          name="registration_certificate"
                          placeholder="registration_certificate"
                          defaultValue={
                            props.tokenData.owner.registration_certificate
                          }
                          onChange={(e) => {
                            setImageUploadRegistrationCertificate(
                              e.target.files[0]
                            );
                          }}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {/* Registration Number */}
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="registration_number"
                      placeholder="Registration Number"
                      defaultValue={
                        props.selectedMerchantData.registration_number
                      }
                      onChange={(e) => setRegistrationNumber(e.target.value)}
                      required
                    />
                    <label>Registration Number</label>
                  </Form.Floating>

                  {/* Legal Name */}
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="legal_name"
                      placeholder="Legal Name"
                      defaultValue={props.selectedMerchantData.legal_name}
                      onChange={(e) => setMerchantLegalName(e.target.value)}
                      required
                    />
                    <label>Legal Name</label>
                  </Form.Floating>

                  {/* Company Structure */}
                  <Form.Floating className="mb-3">
                    <Form.Select
                      aria-label="company_structure"
                      onChange={(e) => setCompanyStructure(e.target.value)}
                      defaultValue={
                        props.selectedMerchantData.company_structure
                          ? props.selectedMerchantData.company_structure
                          : "LLC"
                      }
                    >
                      {/* Sole Proprietorship */}
                      <option value="Sole Proprietorship">
                        Sole Proprietorship
                      </option>
                      {/* LLC */}
                      <option value="LLC">LLC</option>
                      {/* Corporation */}
                      <option value="Corporation">Corporation</option>
                      {/* Partnership */}
                      <option value="Partnership">Partnership</option>
                    </Form.Select>
                    <label>Company Structure</label>
                  </Form.Floating>

                  {/* Merchant Website Link */}
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="url"
                      name="website_link"
                      placeholder="website_link"
                      defaultValue={props.selectedMerchantData.website_link}
                      onChange={(e) => setMerchantWebsiteLink(e.target.value)}
                    />
                    <label>Website Link</label>
                  </Form.Floating>

                  {/* Merchant Country */}
                  <Form.Floating className="mb-3">
                    <CountrySelector
                      country={merchantCountry}
                      setCountry={setMerchantCountry}
                      countryChange={merchantCountryChange}
                    />
                  </Form.Floating>

                  {/* Merchant City */}
                  {merchantCitiesOptions[0] ? (
                    <Form.Floating className="mb-3">
                      <Form.Select
                        className="mb-3"
                        aria-label="merchantcity"
                        onChange={(e) => setMerchantCity(e.target.value)}
                        defaultValue={merchantCity}
                      >
                        {merchantCitiesOptions.map((item, idx) => (
                          <option key={idx} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <label>City</label>
                    </Form.Floating>
                  ) : (
                    <></>
                  )}

                  {/* Merchant Address Line1 */}
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="line1"
                      placeholder="Line1"
                      defaultValue={props.selectedMerchantData.line1}
                      onChange={(e) => setMerchantLine1(e.target.value)}
                      required
                    />
                    <label>Line1</label>
                  </Form.Floating>

                  {/* Merchant Address Line2 */}
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="line2"
                      placeholder="Line2"
                      defaultValue={props.selectedMerchantData.line2}
                      onChange={(e) => setMerchantLine2(e.target.value)}
                    />
                    <label>Line2</label>
                  </Form.Floating>

                  {/* Merchant Postal Code */}
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="number"
                      name="postal_code"
                      placeholder="Postal Code"
                      defaultValue={props.selectedMerchantData.postal_code}
                      onChange={(e) => setMerchantPostalCode(e.target.value)}
                      required
                    />
                    <label>Postal Code</label>
                  </Form.Floating>
                </>
              )}

              {/* Submit Button */}
              <Row style={{ margin: "0" }}>
                <Button
                  className="Loginbutton Lbutton"
                  // onClick={handleSubmit}
                  type="submit"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  Submit
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
}

export default React.memo(OnBoarding);
